<template>
    <div>
        <b-overlay :show="showLoader">
            <b-card>
                <b-table id="table" class="mt-1" responsive striped bordered hover :items="filtered" :fields="fields" :per-page="pagination.per_page" :current-page="pagination.current_page">
                    <template  slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]" :placeholder="field.label.toUpperCase()"/>
                        </b-td>
                    </template>
                    <template v-if="filtered.length === 0" slot="bottom-row">
                        <b-td :colspan="fields.length">
                            <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                        </b-td>
                    </template>
                </b-table>

                <b-row>
                    <b-col class="text-center text-sm-left">
                        <b-dropdown id="table-dropdown" variant="outline" :text="'Na stran: ' + pagination.per_page" size="sm" class="btn-none">
                            <b-dropdown-item v-model="pagination.per_page" v-for="(item, key) in pageOptions" :key="key"  @click="pagination.per_page = item">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-pagination class="mt-1 mt-sm-0 justify-content-center justify-content-sm-end" id="table-pagination" pills
                                      v-model="pagination.current_page"
                                      :total-rows="pagination.total_items"
                                      :per-page="pagination.per_page"
                                      size="sm"
                        />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import {
        BCard,
        BCol,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BPagination,
        BRow,
        BTable,
        BTd,
        BFormInput
    } from 'bootstrap-vue'
    export default {
        components: {
            BDropdown,
            BDropdownItem,
            BPagination,
            BTable,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BTd,
            BFormInput
        },
        data() {
            return {
                showLoader: false,
                data: [],
                pageOptions: [10, 20, 50, 100],
                pagination: {current_page: 1, per_page: 10, total_items: 0},
                filters: {},
                fields: [
                    { key: 'user_name', label: 'Ime', sortable: true, class: 'text-center' },
                    { key: 'user_surname', label: 'Priimek', sortable: true, class: 'text-center' },
                    { key: 'coupon', label: 'Kupon', sortable: true, class: 'text-center' }
                ]
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                thisIns.showLoader = true
                this.$http.get(`/api/management/v1/service/coupon/${thisIns.$route.params.service_id}`)
                    .then(response => {
                        this.data = response.data
                        this.pagination.total_items = this.data.length
                    })
                    .catch(error => {
                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    })
                    .finally(function() {
                        thisIns.showLoader = false
                    })
            }
        },
        computed: {
            filtered() {
                const filtered = this.data.filter(data => {
                    return Object.keys(this.filters).every(key => String(data[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
                return filtered.length > 0 ? filtered : []
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
#table .form-control, #table-dropdown, #table-dropdown .dropdown-menu {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
#table-dropdown .dropdown-toggle::after, .drop .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
#table .custom-checkbox .custom-control-label{
  position: relative;
}
</style>